<template>
  <!-- <img alt="Logo" src="./assets/ff.jpg"> -->
  <img src="./assets/ff.jpg" alt="Logo" style="
    height: 480px;
  ">
  <HelloWorld msg="Under Construction"/>
</template>

<script>
import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    HelloWorld
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
